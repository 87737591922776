<template>
  <div>
    <b-card>
      <div class="d-flex">
        <p class="text-secondary my-auto mr-auto">
          <i :class="$route.meta.iconClass"></i>
          <span
            style="font-size: 22px; color: #7f7f7f"
            class="font-weight-light"
          >
            Fiche Bénéficiaire :
          </span>
          <span
            style="font-size: 25px; color: #343a40"
            class="font-weight-bold"
            >{{ selecteddata.name }}</span
          >
        </p>

        <div class="row mb-3">
          <div class="col-12">
            <modal-actions
              @close="
                $router.go(
                  $store.getters['routerHistory/fromCreation'] ? -2 : -1
                )
              "
            />
          </div>
        </div>
      </div>
      <div class="shdow">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center"
        >
          <p class="h4 text-secondary my-auto mr-auto">
            Informations générales
          </p>
          <a
            v-if="!editMode"
            class="text-secondary"
            style="font-size: 30px; color: #007bffff !important"
            @click.prevent="editMode = !editMode"
            ><i class="mdi mdi-file-document-edit"></i
          ></a>
          <div v-else class="m-0 d-flex p-0">
            <b-button
              style="min-width: 120px"
              type="submit"
              variant="secondary"
              pill
              @click="updateItem"
              :disabled="submitingForm"
            >
              <b-spinner
                v-if="submitingForm"
                small
                class="text-white"
                label="Loading..."
              ></b-spinner>
              Confirmer</b-button
            >
            <b-button
              @click="editMode = !editMode"
              pill
              style="background-color: #adb5bd; border-color: #adb5bd"
              >Annuler</b-button
            >
          </div>
        </div>
        <hr class="mt-1 mb-3 bg-secondary" />
        <div class="row" v-if="!editMode">
          <div class="col-12">
            <div class="my-3">
              <span>Bénéficiaire : </span>
              <span class="font-weight-bold mr-3">{{
                selecteddata.name ? selecteddata.name : ""
              }}</span>
            </div>
            <div class="my-3">
              <span>Type : </span>
              <span class="font-weight-bold mr-3">{{
                selecteddata.type ? selecteddata.type : ""
              }}</span>
            </div>
            <div class="my-3">
              <span>Volume : </span>
              <span class="font-weight-bold mr-3">{{
                selecteddata.volume ? selecteddata.volume : ""
              }}</span>
            </div>
          </div>
        </div>
        <div v-else class="row">
          <!-- First Col -->
          <div class="col-12 col-md-6">
            <b-form-group
              label-cols="4"
              label="Bénéficiaire :"
              label-class="font-weight-bold"
            >
              <b-form-input
                type="text"
                placeholder="Bénificaire"
                :class="{
                  'is-invalid':
                    $v.newBenf.name.$error && $v.newBenf.name.$dirty,
                }"
                v-model="newBenf.name"
              ></b-form-input>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newBenf.name.$dirty"
              >
                {{ !$v.newBenf.name.required ? "champ obligatoire" : "" }}
              </span>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label="Volume :"
              label-class="font-weight-bold"
            >
              <b-form-input
                type="number"
                placeholder="Volume"
                min="0"
                :class="{
                  'is-invalid':
                    $v.newBenf.volume.$error && $v.newBenf.volume.$dirty,
                }"
                v-model="newBenf.volume"
              ></b-form-input>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newBenf.volume.$dirty"
              >
                {{ !$v.newBenf.volume.required ? "champ obligatoire" : "" }}
              </span>
            </b-form-group>
          </div>
          <div class="col-12 col-md-6">
            <b-form-group
              label-cols="4"
              label="Type :"
              label-class="font-weight-bold"
            >
              <v-select
                :class="{
                  'is-invalid form-control p-0':
                    $v.newBenf.type.$error && $v.newBenf.type.$dirty,
                }"
                style="padding-right: 0 !important"
                v-model="newBenf.type"
                :options="['Externe', 'Interne']"
              >
                <template #no-options> Liste vide </template>
              </v-select>
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newBenf.type.$dirty"
              >
                {{ !$v.newBenf.type.required ? "champ obligatoire" : "" }}
              </span>
            </b-form-group>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import { mapGetters } from "vuex";
import { minLength, required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
// import TableActions from "@/components/custom/TableActions.vue";
export default {
  components: {
    ModalActions,
    // TableActions,
  },
  data: () => ({
    editMode: false,
    selecteddata: {
      name: "",
      type: "",
      volume: "",
    },
    newBenf: {
      name: "",
      type: "",
      volume: "",
    },
    affectedTeams: {
      tableItems: [
        {
          team_label: "Lorem Ipsum",
          responsable: "Leslie Bechtelar",
          organisation_attachment: "Cadrage",
          collaborators_number: "4",
          actions: ["edit"],
        },
        {
          team_label: "Guillermo Nienow",
          responsable: "Ms. Melissa Fay",
          organisation_attachment: "Cadrage",
          collaborators_number: "6",
          actions: ["edit"],
        },
      ],
      tableFields: [
        { key: "team_label", label: "Libellé équipe", sortable: true },
        { key: "responsable", label: "Responsable", sortable: true },
        {
          key: "organisation_attachment",
          label: "Organisation de rattachement",
          sortable: true,
        },
        {
          key: "collaborators_number",
          label: "Nombre de collaborateurs",
          sortable: true,
        },
        { key: "actions", label: "" },
      ],
    },
    submitingForm: false,
  }),
  validations: {
    newBenf: {
      name: {
        required,
        minLength: minLength(5),
      },

      type: {
        required,
      },
      volume: {
        required,
      },
    },
  },
  created() {
    this.$store
      .dispatch("beneficiare/fetchBeneficiare", this.$route.params.id)
      .then(() => {
        this.$store.dispatch("loader/toggleLoading", false);
      });
  },

  computed: {
    ...mapGetters("beneficiare", ["Beneficiare"]),
  },
  watch: {
    Beneficiare: function () {
      this.selecteddata = this.Beneficiare;
      this.newBenf.name = this.Beneficiare.name;
      this.newBenf.type = this.Beneficiare.type;
      this.newBenf.volume = this.Beneficiare.volume;
    },
  },
  methods: {
    updateItem() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.$store
          .dispatch("beneficiare/updateBeneficiare", {
            id: this.$route.params.id,
            name: this.newBenf.name,
            type: this.newBenf.type,
            volume: parseInt(this.newBenf.volume),
          })
          .then(() => {
            this.$store
              .dispatch("beneficiare/fetchBeneficiare", this.$route.params.id)
              .then(() => {
                Swal.fire("La tâche est mise à jour !", "", "success");
                this.editMode = false;
              });
          });
      }
    },

    addNewTeamInputs() {
      this.affectedTeams.tableItems.push({ editMode: true });
    },
    removeTeamInputs(itemIndex) {
      this.affectedTeams.tableItems = this.affectedTeams.tableItems.filter(
        (team, index) => index !== itemIndex
      );
    },
  },
};
</script>

<style></style>
